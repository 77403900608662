import * as React from 'react';
import { IColumn, IContextualMenuItem, Icon } from "office-ui-fabric-react";
import { Field } from "../../../entities/Metadata";
import { AuthProvidersMap, Dictionary, EntityType, SortDirection } from "../../../entities/common";
import { IListViewColumn, ViewService } from "../../../services/ViewService";
import { IUser } from "../../../store/UsersListStore";
import { TInlineEditProps, buildEntityColumnImpl } from "../../common/extensibleEntity/EntityDetailsList";
import { UserResourceFields } from "../../../store/user/common";
import * as UsersListStore from '../../../store/UsersListStore';
import { notNull, notUndefined } from "../../utils/common";
import { SortBy } from "../../common/sectionsControl/SectionList";
import { RowMenuColumn } from "../../common/extensibleEntity/RowMenuColumn";
import { RowMenu } from "../../common/extensibleEntity/RowMenu";
import { LicenseTypeMap } from '../../../store/permissions';
import { Subscription } from '../../../store/Tenant';
import { SortService } from '../../../services/SortService';
import { TextFormatter } from '../../common/formatters/TextFormatter';

type UserListColumnsProps = {
    subscription: Subscription,
    userResourceFields: Dictionary<Field>,
    usersActions: typeof UsersListStore.actionCreators;
    getItemCommands: (user: IUser) => IContextualMenuItem[]
    onSelectUser: (userId: string) => void
}


export const BuildUserListColumns = (props: UserListColumnsProps): IColumn[] => {
    const { subscription } = props;
    const columns: (IColumn | undefined)[] = [
        {
            key: "fullName",
            fieldName: "fullName",
            iconName: "Emoji2",
            name: "User Name",
            headerClassName: "with-icon",
            minWidth: 200,
            maxWidth: 500,
            isResizable: true,
            onRender: (item: IUser) => {
                const component = ViewService.createListColumn<IListViewColumn<IUser>>('user/Name');
                return <RowMenuColumn
                    onItemMenuRender={() => <div className="menu"><RowMenu item={item} commands={props.getItemCommands(item)} /></div>} >
                    {onRenderUserColumn(item, undefined, undefined, () => 
                        React.createElement(component, { entity: item, onClick: () => props.onSelectUser(item.id) }))}
                </RowMenuColumn>;
            }
        },
        {
            key: "email",
            fieldName: "email",
            name: "Email",
            minWidth: 100,
            maxWidth: 500,
            isResizable: true,
            onRender: (item: IUser) =>
                onRenderUserColumn(item, undefined, undefined, () => 
                <TextFormatter withNavigation={true} value={item.email} />)
        },
        {
            key: "logonAccount",
            fieldName: "logonAccount",
            name: "Logon Account",
            minWidth: 100,
            maxWidth: 500,
            isResizable: true,
            onRender: onRenderUserColumn
        },
        {
            key: "status",
            fieldName: "status",
            name: "Status",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (item: IUser) =>
                onRenderUserColumn(item, undefined, undefined, () => {
                    const component = ViewService.createListColumn<IListViewColumn<IUser>>('user/Status');
                    return React.createElement(component, { entity: item });
                }),
        },
        subscription.subscriptionId ? {
            key: "license",
            fieldName: "license",
            name: "License",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (user: IUser) =>
                onRenderUserColumn(user, undefined, undefined, () => {
                    const map = LicenseTypeMap[user.license];
                    return <span className={`license ${map.cssClassName}`}>{map.label}</span>;
                }),
        } : undefined,
        {
            key: "authProvider",
            fieldName: "authProvider",
            name: "Auth Provider",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (user: IUser) =>
                onRenderUserColumn(user, undefined, undefined, () => {
                    return user.authProvider && <span className="overflow-text" title={AuthProvidersMap[user.authProvider]?.friendlyName}>{AuthProvidersMap[user.authProvider]?.friendlyName}</span>;
                }),
        },
    ];
    return columns.concat(CreateResourceColumns(props.userResourceFields, props.usersActions)).filter(notUndefined);
}


const CreateResourceColumns = (userResourceFields: Dictionary<Field>, usersActions: typeof UsersListStore.actionCreators)
    : IColumn[] => {

    const _getResourceItemValue = (item: IUser, field: Field) => item.resourceAttributes?.[field.name];
    const _applyResourceItemUpdates = (item: IUser, field: Field, value: any, extraUpdates?: Dictionary<any>): IUser => {
        return {
            ...item,
            resourceAttributes: {
                ...item.resourceAttributes ?? {},
                ...ViewService.buildUpdates(field, value, extraUpdates)
            }
        };
    }

    const _inlineResourceEditProps: TInlineEditProps<IUser> = {
        onInlineEditComplete: (field: Field, item: IUser, value: any, extraUpdates?: Dictionary<any>) => {
            const update = ViewService.buildUpdates(field, value, extraUpdates);
            usersActions.updateResourceAttributes([{ id: item.id, resourceAttributes: update }]);
        },
        readonlyFields: (entity: IUser) => !entity.linkedResource ? Object.keys(UserResourceFields) : [],
    };

    return Object.keys(UserResourceFields).map(fieldName => buildEntityColumnImpl<IUser>(
        fieldName,
        false,
        EntityType.Resource,
        undefined,
        false,
        userResourceFields,
        undefined,
        undefined,
        _getResourceItemValue,
        _applyResourceItemUpdates,
        _inlineResourceEditProps,
    )).filter(notNull);
}

export const onRenderUserColumn = (item: IUser, index?: number, column?: IColumn, defaultRender?: () => JSX.Element | undefined) => {
    if (!column && !defaultRender) {
        return <span></span>;
    }
    const val = item[column?.fieldName!];
    return <div className="grid-editable-cell">
        <div className="formatter" title={val}>
            {defaultRender ? defaultRender() : <div className="overflow-text"> {val}</div>}
        </div>
        <span className="readonly-button">
            <Icon iconName="Uneditable" title="This cell is read-only" />
        </span>
    </div>;
}
